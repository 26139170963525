module.exports = {
  "featureFlags": {
    "header": {
      "replaceWithLogo": false
    },
    "map": {
      "enabled": true
    },
    "location": {
      "defaultAddress": null,
      "customLocationSlug": null
    },
    "startMenu": {
      "createProfile": true
    },
    "showInclusionReason": null,
    "factSheet": true,
    "search": {
      "hideSectionsForLoggedOut": true,
      "disableSearchForLoggedOut": true
    }
  },
  "plugins": {
    "surveys": {
      "enabled": true,
      "canCreate": ["press_profile"]
    },
    "messengerToggle": {
      "enabled": true
    },
    "nonCommentable": {
      "enabled": true,
      "canTrigger": ["press_profile", "private_profile", "blog_profile", "authority_profile"]
    }
  },
  "staticPages": {
    "enableBlog": false,
    "enableAboutUs": false,
    "enableFaq": false,
    "urls": {
      "AboutUs": "https://start.stadt-punkt.de",
      "Blog": "https://start.stadt-punkt.de/blog/",
      "Faq": "https://start.stadt-punkt.de/haeufige-fragen/",
      "Homepage": "https://stadt-punkt.de",
      "Legal": "https://stadt-punkt.de/static/impressum.html",
      "PrivacyPolicy": "https://stadt-punkt.de/static/datenschutz.html",
      "ReleaseNotes": "https://lkpt.de/versionshinweise-app",
      "TermsAndConditions": "https://stadt-punkt.de/static/agb.html",
      "Contact": "https://blog.stadt-punkt.de/kontakt/"
    }
  },
  "tabs": {
    "showNewsFeed": true,
    "showEventFeed": true,
    "showGroups": true,
    "showUserDirectory": true,
    "showNotifications": true
  },
  "languages": [
    {
      "language": "de",
      "isEnabled": true,
      "isDefault": true
    }
  ],
  "links": {
    "googlePlayStore": "http://play.google.com/store/apps/details?id=de.stadthagen.mainapp",
    "appleStore": "itms-apps://itunes.apple.com/us/app/apple-store/id1644914721?mt=8"
  },
  "gtm": {
    "id": ""
  },
  "eyeable": {
    "id": "stadt-punkt.de",
    "enabled": true
  }
}
;